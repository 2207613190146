import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware((to, from) => {
    const AuthStore = useAuthStore()
    const { $lang } = useNuxtApp()

    if (!AuthStore.IsAuth) {
        const pathsToMAtch = [
            `/${$lang.routes.share}/${$lang.routes.chat}`,
            `/${$lang.routes.share}/${$lang.routes.coupon}`,
            `/${$lang.routes.share}/${$lang.routes.offer}`,
        ]
        if (to.path && pathsToMAtch.find((path) => path === to.path)) {
            return navigateTo('/' + $lang.routes.login, { redirectCode: 302 })
        }

        abortNavigation({ statusCode: 401, message: 'Unauthorized' })
    }
})
